import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useContextPage } from '../../contextPage'
import { AppearFromDown } from '../../../../../components/templates/AppearFromDown'
import { UnitType } from '../../../../../domain/models/UnitModel'
import {
  EntityCard,
  EntityCardProps
} from '../../../../../components/organisms/EntityCard'
import { InfoText } from '../../../../../components/templates/InfoText'
import { TextInput } from '../../../../../components/atoms/TextInput'

const UnitsList = () => {
  const { state, setState } = useContextPage()
  const [entityCards, setEntityCards] = React.useState<EntityCardProps[]>([])

  React.useEffect(() => {
    if (state.units) {
      const result = createEntityCardsList(state.units)
      setEntityCards(result as Required<EntityCardProps[]>)
    }
  }, [state?.units, state.unitSelected])

  const createEntityCardsList = (units: UnitType[]) => {
    const unitsCards = units
      .filter((unit) => !unit.contract) // Remove units that already has a contract
      .map((unit) => {
        const iconHome = unit.real_total_area ? unit.real_total_area : '--'
        const parkingSlots = state.projectsSelected?.parking_slots.filter(
          (parking) => parking.unit_id == unit.id
        )
        const disabledSwitch =
          !unit.is_complete ||
          (!!state.unitSelected && unit.id !== state.unitSelected?.id)
        const alert = {
          badge: {
            type: 'badge badge-light-danger',
            status: 'Cadastro incompleto'
          }
        }
        const cardProps = {
          title: `${unit.building.name} - ${unit.name}`,
          image: unit.photo,
          withSwitch: true,
          disabledSwitch: disabledSwitch,
          defaultChecked: state?.unitSelected?.id === unit.id,
          line1: state.projectsSelected?.name,
          logoOrPhoto: 'photo',
          photoEdition: false,
          leftBtnDisabled: true,
          onChangeSwitch: (wasSelected: boolean) => {
            wasSelected
              ? setState({ ...state, unitSelected: unit })
              : setState({ ...state, unitSelected: undefined })
          },
          rightBtnAction: () => window.open(`/units/${unit.id}`, '_blank'),
          icons: [
            {
              icon: 'icon-home',
              text: `${iconHome} m²`
            },
            { icon: '', text: `${parkingSlots?.length} vaga(s)` }
          ]
        }

        return Object.assign(cardProps, !unit.is_complete && alert)
      })
    return unitsCards
  }

  const [search, setSearch] = useState('')

  const filteredEntityCards = entityCards
    .filter((entityCard) =>
      !search
        ? true
        : entityCard.title?.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a, b) =>
      (a.title ? a.title : 0) > (b.title ? b.title : 0) ? 1 : -1
    )

  console.log({
    filteredEntityCards,
    search
  })

  return (
    <>
      <Card>
        <Col style={{ padding: '20px' }} md={12}>
          <TextInput
            label="Buscar unidade"
            type="text"
            placeholder="Digite aqui sua busca"
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
          ></TextInput>
        </Col>
      </Card>
      {!!state.units && (
        <AppearFromDown key={state.projectsSelected?.id}>
          <div>
            <Row>
              {filteredEntityCards?.map((entityCard, index) => (
                <Col key={`entity-card-${index}`} xl={3} sm={6}>
                  <EntityCard {...entityCard} />
                </Col>
              ))}
              {!filteredEntityCards.length && (
                <InfoText>Não foi encontrada nenhuma Unidade.</InfoText>
              )}
            </Row>
          </div>
        </AppearFromDown>
      )}
    </>
  )
}

export default UnitsList
